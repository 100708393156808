<template>
  <section class="hero-section">
    <div class="background-image">
      <GlitchImg :image="require('@/assets/images/lazyrave_band_live_2021.jpg')" />
      <div class="image-gradient" />
    </div>
    <div class="hero-block">
      <nav class="hero-nav">
        <a
          v-for="(n, index) in navs"
          :key="index"
          class="nav-item"
          :href="n.href"
        >
          {{ n.text }}
        </a>
      </nav>
      <div
        class="logo-text text-glitch"
        style="animation-delay: 5s;"
      >
        <!--Heavy on the will &gt;&gt;to disobey&lt;&lt;-->
      </div>
      <div class="logo">
        <GlitchImg
          alt="LAZYRAVE logo"
          :image="require('@/assets/logo/logo-white.png')"
          delay="3s"
          style="overflow: visible"
        />
      </div>
      <div class="socials-icons">
        <SocialIcon
          icon="instagram-green"
          link="https://instagram.com/lazyrave/"
        />
        <SocialIcon
          icon="spotify-green"
          link="https://open.spotify.com/artist/3AfVo7omZ2E22MfF27d5ca"
        />
        <SocialIcon
          icon="youtube-green"
          link="https://www.youtube.com/channel/UCe_1cX5PXT2EavXsYbX4PkA"
        />
      </div>
      <div
        id="tours"
        class="container"
      >
        <GrayBlock>
          <h1>Tours <span class="text-stroke"> & streams</span></h1>
          <Tours />
        </GrayBlock>
      </div>
    </div>
  </section>
</template>

<script>
import { SocialIcon, GrayBlock, GlitchImg } from '@/components/'
import Tours from './Tours.vue'

export default {
  name: 'Ticker',
  components: {
    SocialIcon,
    GrayBlock,
    Tours,
    GlitchImg,
  },
  data() {
    return {
      navs: [
        {
          text: 'tours',
          href: '#tours',
        },
        {
          text: 'Press',
          href: '#press',
        },
        {
          text: 'Merch',
          href: 'https://merch.lazyrave.co.uk',
        },
        {
          text: 'Links',
          href: '/links',
        },
      ],
    }
  },
}
</script>

<style lang="scss">
.hero-section {
  position: relative;
  z-index: 1;
  margin: 0;
  padding: 0;
  width: 100%;
  .background-image {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    height: 924px;
    @media (max-width: $lx) {
      height: 793px;
    }
    .image-gradient {
      position: absolute;
      z-index: 3;
      left: 0;
      right: 0;
      bottom: 0;
      height: 179px;
      background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    }
    .glitch {
      opacity: 0.6;
    }
  }
  .hero-block {
    position: relative;
    z-index: 5;
  }
  .hero-nav {
    text-align: center;
    padding: 55px 0 150px;
    @media (max-width: $lx) {
      padding-bottom: 130px;
    }
    @media (max-width: $sm) {
      padding-bottom: 30px;
    }
    .nav-item {
      text-decoration: none;
      text-transform: uppercase;
      padding: 0 15px;
      @include font(18px, 22px, 600,0.05em);
      @media (max-width: $lx) {
        @include font(16px, 20px);
      }
    }
  }
  .socials-icons {
    margin-top: 30px;
    margin-bottom: 215px;
    @media (max-width: $lx) {
      margin-top: 50px;
      margin-bottom: 160px;
    }
    @media (max-width: $sm) {
      margin-top: 20px;
      margin-bottom: 85px;
    }
  }
  .logo {
    position: relative;
    width: 749px;
    height: 184px;
    margin: 0 auto;
    @media (max-width: $lx) {
      width: 557px;
      height: 138px;
    }
    @media (max-width: $sm) {
      width: 269px;
      height: 60px;
    }
  }
  .logo-text {
    @include font(24px, 28px, 600, 0.05em);
    text-transform: uppercase;
    margin-bottom: 25px;
    text-align: center;
    @media (max-width: $lx) {
      @include font(20px, 24px);
    }
    @media (max-width: $sm) {
      padding-top: 60px;
    }
  }
  h1 {
    margin-top: 0;
    margin-bottom: 30px;
    @media (max-width: $lx) {
      margin-bottom: 15px;
    }
    @media (max-width: $md) {
      margin-bottom: 10px;
    }
  }
}
#tours {
  .gray-block-inner {
    padding-bottom: 15px;
    @media (max-width: $sm) {
      padding: 25px 16px 5px;
    }
  }
}
</style>
