<template>
  <section class="signup-wrap">
    <div class="container">
      <GrayBlock>
        <div class="signup-with-background" />
        <div class="signup-inner-elements">
          <h1>
            WE ARE <br>
            <span class="text-stroke">
              LAZYRAVE
            </span>
          </h1>
        </div>
      </GrayBlock>
    </div>
  </section>
</template>

<script>
import { GrayBlock } from '@/components'

export default {
  name: 'Signup',
  components: {
    GrayBlock,
  },
  data() {
    return {
      email: '',
    }
  },
  methods: {
    sendEmail(email) {
      return !!email
    },
  },
}
</script>

<style lang="scss">
.signup-wrap {
  display: block;
  position: relative;
  z-index: 1;
  @media (max-width: $sm) {
    .gray-block {
      margin: 0;
      .gray-block-inner {
        padding:  20px 10px;
      }
    }
  }
  .signup-with-background {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('~@/assets/images/lazyrave_band_side_2021.jpg');
    background-size: cover;
    background-position: center;
    opacity: 0.5;
  }
  .signup-inner-elements {
    position: relative;
    z-index: 2;
    padding: 70px 0 95px;
    @media (max-width: $lx) {
      padding: 10px 0 45px;
    }
    @media (max-width: $sm) {
      padding: 10px 0;
    }
  }

  h1 {
    text-align: center;
    margin-bottom: 40px;
    @media (max-width: $lx) {
      margin-bottom: 45px;
    }
    @media (max-width: $sm) {
      margin: 0 0 15px;
    }
  }
  .signup-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 764px;
    margin: 0 auto;
    background: rgba($color-white, 0.2);
    padding: 22px;
    @media (max-width: $lx) {
      width: 623px;
      padding: 10px;
    }
    @media (max-width: $md) {
      display: block;
      background: transparent;
      width: auto;
      padding: 0;
    }
    input {
      flex: 3 0 245px;
      border: none;
      @include font(24px, 30px, 500);
      background: transparent;
      color: $color-gray;
      padding: 0 20px;

      &:focus, &:active {
        border: none;
        outline: none;
      }
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0px 1000px rgba(100, 100, 100, 1) inset !important;
      }

      @media (max-width: $md) {
        display: block;
        width: 100%;
        padding: 15px;
        border: 1px solid $color-white;
        background: rgba($color-white, 0.2);
      }
    }
    .button {
      flex: 0 1 245px;
      text-transform: uppercase;
      padding: 30px 30px;
      margin-bottom: 0;
      @media (max-width: $lx) {
        flex-basis: 170px;
        padding: 25px 5px;
      }
      @media (max-width: $md) {
        margin-top: 15px;
        padding: 25px 60px;
      }
      @media (max-width: $sm) {
        margin-top: 10px;
        padding: 15px 40px;
        @include font(12px, 15px, none, 0.05em);
      }
    }
  }
}
</style>
