<template>
  <button
    ref="button"
    class="button"
    :class="[getType, getSize]"
    :target="blank ? '_blank' : undefined"
    :tabindex="tabindex"
    :disabled="disabled"
    @click.prevent="buttonClicked"
    @hover="onHover"
  >
    <img
      v-if="!!icon"
      :src="icon"
      class="button-icon"
    >
    <slot />
    <img
      v-if="!!iconEnd"
      :src="iconEnd"
      class="button-icon icon-end"
    >
  </button>
</template>

<script>
import gsap from 'gsap'

export default {
  name: 'Btn',
  props: {
    href: {
      reqired: false,
      type: String,
    },
    click: {
      reqired: false,
      type: Function,
    },
    icon: {
      reqired: false,
      type: String,
    },
    iconEnd: {
      reqired: false,
      type: String,
    },
    size: {
      reqired: false,
      type: String,
    },
    primary: { reqired: false, type: Boolean, default: false },
    cancel: { reqired: false, type: Boolean, default: false },
    small: { reqired: false, type: Boolean, default: false },
    large: { reqired: false, type: Boolean, default: false },
    blank: { reqired: false, type: Boolean, default: false },
    disabled: { reqired: false, type: Boolean, default: false },
    loading: { reqired: false, type: Boolean, default: false },
    tabindex: { reqired: false, type: Boolean, default: false },
  },
  data() {
    return {
      animate: false,
      clicked: false,
      gui: false,
      turb: false,
    };
  },
  computed: {
    getType() {
      if (this.primary) {
        return 'button-primary';
      }
      if (this.cancel) {
        return 'button-cancel';
      }
      return '';
    },
    getSize() {
      if (!this.size) {
        return `button-${this.size}`;
      }
      if (this.small) {
        return 'button-small';
      }
      if (this.large) {
        return 'button-large';
      }
      return '';
    },
  },
  mounted() {
    const self = this;
    const bt = self.$refs.button
    const turbVal = { val: 0.000001 };
    const filters = document.querySelectorAll('#filter-glitch-3 feTurbulence');
    if (filters[0]) {
      // eslint-disable-next-line prefer-destructuring
      self.turb = filters[0]
      self.btTl = gsap.timeline({
        paused: true,
        onUpdate() {
          self.turb.setAttribute('baseFrequency', `0.00001 ${turbVal.val}`); // Firefox bug is value is 0
        },
        onStart() {
          bt.style.filter = 'url(#filter-glitch-3)';
        },
        onComplete() {
          bt.style.filter = 'none';
        },
      });

      self.btTl.to(turbVal, 0.4, { val: 0.4 });
      self.btTl.to(turbVal, 0.2, { val: 0.000001 });
      setInterval(() => {
        self.btTl.restart()
      }, 3000)
    }
  },
  methods: {
    buttonClicked(e) {
      if (this.loading || this.disabled) {
        return;
      }

      this.clicked = true;
      this.$emit('click', e);
      if (this.blank) {
        window.open(this.href);
      } else if (this.href && this.href.trim() !== '') {
        window.location.href = `${this.href}`;
      }
      if (this.click) {
        this.click();
      }
    },
    onHover() {
      this.btTl.restart();
      console.log('on hover')
    },
    updateAnimation() {
      console.log(this.turb)
      this.turb.setAttribute('baseFrequency', '0.001 0.001');
    },
  },
};
</script>

<style lang="scss">
.button {
  @include font(16px, 20px, none, 0.05em);
  display: inline-block;
  position: relative;
  overflow: hidden;
  z-index: 0;
  padding: 20px;
  text-align: center;
  margin-bottom: 10px;
  color: $color-black;
  background: $color-primary;
  box-shadow: none;
  border: none;
  cursor: pointer;
  outline: none;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  user-select: none;
  transform: translateZ(0);
  outline: 90px solid transparent !important;
  &:hover {
    background: lighten($color-primary, 15);
    color: $color-black;
  }
  &:active {
    color: $color-black;
    background: lighten($color-primary, 15);
  }
  &:focus {
    outline: none;
  }

  @media (max-width: $lx) {
    @include font(16px, 16px, none, 0.02em);
  }

  .button-icon {
    width: 15px;
    height: 15px;
    margin-right: 8px;
    &.icon-end {
      margin-right: 0;
      margin-left: 8px;
    }
  }

  &.block {
    display: block;
    width: 100%;
  }

  &.disabled,
  &.loading {
    color: #656565;
    background: #383838;
    cursor: not-allowed;
    &:hover,
    &:focus {
    color: #656565;
      background:  #383838;
    }
  }
  &.button-small {
    padding: 15px 20px;
  }
  &.button-large {
    border-radius: 12px;
    padding: 20px 65px;
  }
}
</style>
