<template>
  <section
    id="live"
    class="live-block"
  >
    <div class="noise-block" />
    <div class="live-block-front">
      <div class="container">
        <h1>
          #Lazyrave
          <span class="red-dot" />
          <span class="text-stroke"> LIVE </span>
        </h1>
        <a
          href="https://www.instagram.com/lazyrave/"
          target="blank"
          class="instagram-link"
        >
          @lazyrave
        </a>
      </div>
      <div class="instagram-slider">
        <slider
          ref="slider"
          :options="{
            currentPage: 0,
            infinite: 3,
            slidesToScroll: getSlidersScrollItems(),
            loop: true,
            pagination: false,
            center: true,
          }"
        >
          <slideritem
            v-for="(item, n) in instagramImages"
            :key="n"
          >
            <div class="instagram-item">
              <div class="item-image">
                <GlitchImg :image="getUri(item)" />
              </div>
            </div>
          </slideritem>
        </slider>
      </div>
      <div class="instagram-button">
        <btn
          href="https://www.instagram.com/lazyrave/"
          :icon="require('@/assets/icons/instagram-black.svg')"
          blank
        >
          Follow our instagram
        </btn>
      </div>
    </div>
  </section>
</template>

<script>
import moment from 'moment'
import { slider, slideritem } from 'vue-concise-slider'
import { Btn, GlitchImg } from '@/components'

export default {
  name: 'Live',
  components: {
    Btn,
    slider,
    slideritem,
    GlitchImg,
  },
  data() {
    return {
      instagramImages: [],
    }
  },
  async created() {
    const response = await fetch('/instagram')
    if (response.ok) {
      try {
        const json = await response.json();
        const data = json.graphql.user.edge_owner_to_timeline_media.edges
        const images = []
        data.forEach((img) => {
          images.push(img.node.display_url)
        })
        this.instagramImages = images
      } catch (e) {
        console.log('Cant pull from Instagram')
      }
    } else {
      console.log('Invalid response from Instagram')
    }
  },
  methods: {
    getDate(date) {
      return moment(date).format('D M Y')
    },
    getSlidersScrollItems() {
      if (window.innerWidth >= 1200) { return 3 }
      if (window.innerWidth >= 768) { return 1 }
      return 1
    },
    getUri(url) {
      return encodeURI(url)
    },
  },
}
</script>

<style lang="scss">
.live-block {
  display: block;
  position: relative;
  z-index: 2;
  margin: 60px 0 0;
  padding: 130px 0 110px;
  @media (max-width: $lx) {
    padding: 55px 0 85px;
  }
  @media (max-width: $sm) {
    margin-top: 45px;
    padding-bottom: 45px;
  }
  .noise-block {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url('~@/assets/images/noise-2.jpeg');
    background-repeat: repeat;
    animation: noise .2s infinite linear;
    opacity: 0.14;
  }
  .live-block-front {
    position: relative;
    z-index: 3;
  }
  h1 {
    margin-bottom: 20px;
    text-align: center;
    .red-dot {
      display: inline-block;
      vertical-align: middle;
      width: 16px;
      height: 16px;
      border-radius: 16px;
      background: red;
      opacity: 1;
      animation: blinker 2s linear infinite;
    }
  }
  .instagram-link {
    @include font(24px, 30px);
    color: rgba($color-white, 0.5);
    text-transform: uppercase;
    @media (max-width: $sm) {
      @include font(16px, 20px);
    }
  }
  .swiper-container-horizontal>*>.slider-wrapper {
    align-items: flex-start;
  }
  .instagram-slider {
    margin: 45px 0 75px;
    @media (max-width: $lx) {
      margin-bottom: 65px;
    }
    @media (max-width: $sm) {
      margin: 20px 12px 30px;
    }
  }
  .slider-item {
    display: block;
    width: 422px;
    margin-right: 30px;
    text-align: left;
  }
  .instagram-item {
    display: block;
    height: 422px;
    overflow: auto;

    .item-image {
      position: relative;
      z-index: 1;
      width: 422px;
      height: 422px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }

  @media (max-width: $lx) {
    .slider-item {
      width: 342px;
    }
    .instagram-item {
      height: 342px;
      .item-image {
        width: 342px;
        height: 342px;
      }
    }
  }
  @media (max-width: $sm) {
    .slider-item {
      width: 167px;
      height: 167px;
      margin-right: 9px;
      margin-bottom: 9px;
    }
    .instagram-item {
      height: 167px;
      .item-image {
        width: 167px;
        height: 167px;
      }
    }
  }

  .instagram-button {
    .button {
      font-size: 20px;
      line-height: 40px;
      padding: 20px 40px;
      @media (max-width: $lx) {
        @include font(16px, 20px);
        padding: 15px 40px;
      }
      @media (max-width: $sm) {
        @include font(14px, 17px);
        padding: 5px 25px;
      }
    }
    .button-icon {
      width: 40px;
      height: 40px;
      vertical-align: middle;
    }
  }

}
</style>
