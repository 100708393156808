<template>
  <section class="home-footer">
    <div class="icons">
      <SocialIcon
        icon="instagram-white"
        link="https://instagram.com/lazyrave"
      />
      <SocialIcon
        icon="spotify-white"
        link="https://open.spotify.com/artist/3AfVo7omZ2E22MfF27d5ca"
      />
      <SocialIcon
        icon="youtube-white"
        link="https://www.youtube.com/channel/UCe_1cX5PXT2EavXsYbX4PkA"
      />
    </div>
    <div class="footer-rights">
      © 2021 LAZYRAVE. all raves reserved
    </div>
  </section>
</template>

<script>
import { SocialIcon } from '@/components'

export default {
  name: 'Signup',
  components: {
    SocialIcon,
  },
}
</script>

<style lang="scss">
.home-footer {
  display: block;
  position: relative;
  z-index: 1;
  margin: 130px 0;
  @media (max-width: $sm) {
    margin: 50px 0;
  }
  .social-icon {
    margin: 0 30px;
    @media (max-width: $sm) {
      margin: 0 10px;
      svg {
        width: 52px;
        height: 52px;
      }
    }
  }
  .footer-rights {
    padding-top: 50px;
    text-transform: uppercase;
    @include font(16px, 19px);
    @media (max-width: $lx) {
      @include font(14px, 17px);
    }
    @media (max-width: $sm) {
      padding-top: 20px;
      @include font(12px, 15px);
    }
  }
}
</style>
