<template>
  <div
    class="social-icon"
    :class="classes"
  >
    <a
      :href="link"
      :target=" self ? '_self' : '_blank'"
      v-html="require(`!html-loader!../../assets/icons/${icon}.svg`)"
    />
  </div>
</template>

<script>
export default {
  name: 'SocialIcon',
  props: {
    icon: { type: String, required: true },
    link: { type: String, required: true },
    gray: { type: Boolean, default: false },
    white: { type: Boolean, default: false },
    opacity: { type: Boolean, default: false },
    self: { type: Boolean, default: false },
  },
  computed: {
    classes() {
      const classes = []
      if (this.gray) classes.push('icon-gray')
      if (this.white) classes.push('icon-white')
      if (this.opacity) classes.push('icon-opacity')

      return classes
    },
  },
}
</script>

<style lang="scss">
.social-icon {
  display: inline-block;
  margin: 0 16px;
  cursor: pointer;
  &:hover {
    transform: scale(1.4);
  }
  svg {
    width: 64px;
    height:  64px;
  }
  &.icon-opacity{
    opacity: 0.2;
    &:hover {
      opacity: 1;
    }
  }
  @media (max-width: $sm) {
    svg {
      width: 40px;
      height: 40px;
    }
  }
}
</style>
