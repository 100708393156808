<template>
  <div class="ticker-wrap">
    <div class="ticker-message">
      <div
        v-for="(message, index) in messages"
        :key="index"
        class="ticker-item"
      >
        <span>{{ message.date }}</span>
        <span>{{ message.name }}</span>
        <span>{{ message.location }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Ticker',
  data() {
    return {
      messages: [
        {
          date: 'Feb 11, 2023',
          name: 'Little Buildings ',
          location: 'Newcastle',
        },
        {
          date: 'Mar 11, 2023',
          name: 'Carlton Club ',
          location: 'Manchester',
        },
        {
          date: 'Jul 1, 2023',
          name: 'Coastella ',
          location: 'Festival',
        },
        {
          date: 'Sept 1, 2023',
          name: 'Lindisfarne ',
          location: 'Festival',
        },
      ],
    }
  },
}
</script>

<style lang="scss">
.ticker-wrap {
  position: relative;
  z-index: 100;
  width: 100%;
  height: 32px;
  padding: 0;
  margin: 0;
  background: $color-white;
}
.ticker-message {
  display: inline-block;
  height: 32px;
  line-height: 32px;
  white-space: nowrap;
  box-sizing: content-box;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: ticker;
  animation-duration: 30s;
  padding-left: 100%;
  @media (max-width: $sm) {
    height: 25px;
    line-height: 25px;
  }
  .ticker-item {
    display: inline-block;
    position: relative;
    z-index: 1;
    @include font(12px, 32px, none, 0.15em);
    color: $color-black;
    text-transform: uppercase;
    padding: 0 20px;
    @media (max-width: $sm) {
      @include font(10px, 12px);
    }
    span {
      font: inherit;
      color: inherit;
      margin: 0 10px;
    }
    &:not(:first-child) {
      &::before {
        content: '|';
        position: absolute;
        left: 0;
        font: inherit;
        color: inherit;
      }
    }
  }
}

</style>
