<template>
  <section
    id="press"
    class="press-wrap"
  >
    <div class="container">
      <h1>Press</h1>
      <svg
        v-show="prevShow"
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="nav-button nav-prev"
        @click="prevPage"
      >
        <rect
          width="48"
          height="48"
          rx="24"
          transform="matrix(-1 0 0 1 48 0)"
          fill="#80FF00"
        />
        <path
          d="M28 12L16 24L28 36"
          stroke="black"
          stroke-width="4"
        />
      </svg>
      <svg
        v-show="nextShow"
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="nav-button nav-next"
        @click="nextPage"
      >
        <rect
          width="48"
          height="48"
          rx="24"
          transform="matrix(-1 0 0 1 48 0)"
          fill="#80FF00"
        />
        <path
          d="M20 12L32 24L20 36"
          stroke="black"
          stroke-width="4"
        />
      </svg>
      <slider
        ref="slider"
        :options="{
          currentPage: 0,
          infinite: 3,
          slidesToScroll: getSlidersScrollItems(),
          loop: false,
          pagination: false,
        }"
        @slide="onSlide"
      >
        <slideritem
          v-for="(item, n) in press"
          :key="n"
        >
          <div class="press-item">
            <div
              class="item-image"
            >
              <a v-bind:href="getUri(item.link)"><GlitchImg
                :image="getUri(item.image)"
              /></a>
            </div>
            <p class="item-date">
              {{ moment(item.date).format('DD MMM Y') }}
            </p>
            <p class="item-text">
              {{ item.name }}
            </p>
          </div>
        </slideritem>
      </slider>
    </div>
  </section>
</template>

<script>
import press from '@/assets/press.json'
import moment from 'moment'
import { slider, slideritem } from 'vue-concise-slider'
import { GlitchImg } from '@/components'

export default {
  name: 'Ticker',
  components: {
    slider,
    slideritem,
    GlitchImg,
  },
  data() {
    return {
      press,
      moment,
      nextShow: true,
      prevShow: false,
    }
  },
  methods: {
    getDate(date) {
      return moment(date).format('D M Y')
    },
    getSlidersScrollItems() {
      if (window.innerWidth >= 1200) { return 3 }
      if (window.innerWidth >= 768) { return 2 }
      return 1
    },
    getUri(url) {
      return encodeURI(url)
    },
    prevPage() {
      this.$refs.slider.pre()
    },
    nextPage() {
      this.$refs.slider.next()
    },
    onSlide(data) {
      this.prevShow = !(data.currentPage === 0)
      this.nextShow = this.getSlidersScrollItems() + data.currentPage < this.press.length
    },
  },
}
</script>

<style lang="scss">
.press-wrap {
  display: block;
  position: relative;
  z-index: 1;
  margin: 60px 0;
  @media (max-width: $lx) {
    margin-bottom: 90px;
  }
  @media (max-width: $sm) {
    margin: 30px 0;
  }
  h1 {
    margin-bottom: 50px;
    @media (max-width: $lx) {
      margin-bottom: 30px;
    }
    @media (max-width: $sm) {
      margin: 30px 0 25px;
    }
  }
  .swiper-container-horizontal>*>.slider-wrapper {
    align-items: flex-start;
  }
  .container {
    position: relative;
    z-index: 1;
  }
  .nav-button{
    position: absolute;
    top: #{calc(50% - 25px)};
    left: -75px;
    cursor: pointer;
    transition: transform .3s linear;
    &:hover {
      transform: scale(1.5);
    }
    &.nav-next {
      right: -75px;
      left: auto;
    }
  }
  .slider-container {
    @media (max-width: $sm){
      overflow: visible;
    }
  }
  .slider-item {
    display: block;
    width: 525px;
    margin-right: 25px;
    text-align: left;
    @media (max-width: $lx) {
      width: 384px;
    }
    @media (max-width: $sm) {
      margin-right: 10px;
      width: 255px;
    }
  }
  .press-item {
    display: block;
    width: 100%;
    overflow: auto;

    .item-image {
      position: relative;
      z-index: 1;
      width: 525px;
      height: 300px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      @media (max-width: $lx) {
        width: 384px;
        height: 218px;
      }
      @media (max-width: $sm) {
        width: 255px;
        height: 145px;
      }
    }
    .item-date {
      @include font(17px, 21px);
      color: rgba($color-white, 0.5);
      padding: 30px 0 10px;
      margin: 0;
      text-transform: uppercase;
      @media (max-width: $lx) {
        @include font(15px, 18px);
      }
      @media (max-width: $sm) {
        padding-top: 15px;
        @include font(14, 17px);
      }
    }
    .item-text {
      white-space: normal;
      @include font(24px, 30px);
      margin: 0;
      @media (max-width: $lx) {
        @include font(18px, 26px);
      }
      @media (max-width: $sm) {
        @include font(16px, 21px);
      }
    }
  }

}
</style>
