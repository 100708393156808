<template>
  <div class="gray-block">
    <div
      class="gray-block-inner"
      :style="{'background-image' : backgroundImage}"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'GrayBlock',
  props: {
    backgroundImage: {
      type: String,
    },
  },
}
</script>

<style lang="scss">
.gray-block {
  display: block;
  position: relative;
  z-index: 1;
  margin: 50px 0;
  @media (min-width: $lx) {
    margin: 30px 0;
  }
  &:after {
    content: '';
    position: absolute;
    z-index: 2;
    background: $color-primary;
    width: 100%;
    height: 100%;
    top: 10px;
    left: 10px;
  }
  .gray-block-inner {
    position: relative;
    z-index: 3;
    padding: 72px;
    background: $color-gray-dark;
    @media (min-width: $lx) {
      padding: 55px 65px;
    }
  }
}
</style>
