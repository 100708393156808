<template>
  <div id="page-home">
    <Ticker />
    <HeroSection />
    <Press />
    <Live />
    <Signup />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Ticker from '@/components/Home/Ticker.vue'
import HeroSection from '@/components/Home/HeroSection.vue'
import Press from '@/components/Home/Press.vue'
import Live from '@/components/Home/Live.vue'
import Signup from '@/components/Home/Signup.vue'
import Footer from '@/components/Home/Footer.vue'

export default {
  name: 'Home',
  components: {
    HeroSection,
    Ticker,
    Press,
    Live,
    Signup,
    Footer,
  },
}
</script>

<style lang="scss">
  #page-home {
    overflow: hidden;
  }
</style>
