var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"live-block",attrs:{"id":"live"}},[_c('div',{staticClass:"noise-block"}),_c('div',{staticClass:"live-block-front"},[_vm._m(0),_c('div',{staticClass:"instagram-slider"},[_c('slider',{ref:"slider",attrs:{"options":{
          currentPage: 0,
          infinite: 3,
          slidesToScroll: _vm.getSlidersScrollItems(),
          loop: true,
          pagination: false,
          center: true,
        }}},_vm._l((_vm.instagramImages),function(item,n){return _c('slideritem',{key:n},[_c('div',{staticClass:"instagram-item"},[_c('div',{staticClass:"item-image"},[_c('GlitchImg',{attrs:{"image":_vm.getUri(item)}})],1)])])}),1)],1),_c('div',{staticClass:"instagram-button"},[_c('btn',{attrs:{"href":"https://www.instagram.com/lazyrave/","icon":require('@/assets/icons/instagram-black.svg'),"blank":""}},[_vm._v(" Follow our instagram ")])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('h1',[_vm._v(" #Lazyrave "),_c('span',{staticClass:"red-dot"}),_c('span',{staticClass:"text-stroke"},[_vm._v(" LIVE ")])]),_c('a',{staticClass:"instagram-link",attrs:{"href":"https://www.instagram.com/lazyrave/","target":"blank"}},[_vm._v(" @lazyrave ")])])}]

export { render, staticRenderFns }