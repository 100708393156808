<template>
  <div
    class="glitch"
    :style=" {width , height, '--delay': delay }"
  >
    <div
      :style="{ 'background-image': `url('${image}')`}"
      :alt="alt ? alt : null"
      class="glitch__img"
    />
    <div
      class="glitch__img"
      :style="{ 'background-image': `url('${image}')`}"
    />
    <div
      class="glitch__img"
      :style="{ 'background-image': `url('${image}')`}"
    />
    <div
      class="glitch__img"
      :style="{ 'background-image': `url('${image}')`}"
    />
    <div
      class="glitch__img"
      :style="{ 'background-image': `url('${image}')`}"
    />
  </div>
</template>

<script>
export default {
  name: 'GlitchImg',
  props: {
    image: { type: String },
    alt: { type: String },
    width: { type: String, default: '100%' },
    height: { type: String, default: '100%' },
    delay: { type: String, default: '2s' },
  },
}
</script>

<style lang="css">

.glitch {
  --gap-horizontal: 10px;
  --gap-vertical: 5px;
  --time-anim: 4s;
  --delay-anim: 2s;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.glitch__img {
  position: absolute;
  top: calc(-1 * var(--gap-vertical));
  left: calc(-1 * var(--gap-horizontal));
  width: calc(100% + var(--gap-horizontal) * 2);
  height: calc(100% + var(--gap-vertical) * 2);
  background-color: var(--blend-color-1);
  background-size: cover;
  transform: translate3d(0,0,0);
  background-blend-mode: var(--blend-mode-1);
  animation-delay: var(--delay);
}

.glitch__img:nth-child(n+2) {
  opacity: 0;
}

.glitch__img:nth-child(n+2) {
  animation-duration: var(--time-anim);
  animation-delay: calc(var(--delay-anim) + var(--delay));
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.glitch__img:nth-child(2) {
  background-color: var(--blend-color-2);
  background-blend-mode: var(--blend-mode-2);
  animation-name: glitch-anim-1;
}

.glitch__img:nth-child(3) {
  background-color: var(--blend-color-3);
  background-blend-mode: var(--blend-mode-3);
  animation-name: glitch-anim-2;
}

.glitch__img:nth-child(4) {
  background-color: var(--blend-color-4);
  background-blend-mode: var(--blend-mode-4);
  animation-name: glitch-anim-3;
}

.glitch__img:nth-child(5) {
  background-color: var(--blend-color-5);
  background-blend-mode: var(--blend-mode-5);
  animation-name: glitch-anim-flash;
}

</style>
