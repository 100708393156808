<template>
  <div class="tours-wrap">
    <div
      v-for="(tour, index) in tours"
      :key="index"
      class="tours-item"
    >
      <div class="tour-date">
        <span class="tour-date-month">{{ moment(tour.date).format('D MMM') }}</span>
        <span class="tour-date-year text-stroke"> {{ moment(tour.date).format('Y') }}</span>
      </div>
      <div class="tour-text">
        <div class="tour-name">
          {{ tour.name }}
        </div>
        <div class="tour-arrows">
          >>>>>>>>>>>>>>>>>>>>>>>>
        </div>
        <div class="tour-action">
          <Btn
            :href="tour.link"
            blank
          >
            {{ tour.button }}
          </Btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tours from '@/assets/tours.json'
import { Btn } from '@/components'
import moment from 'moment'

export default {
  name: 'Ticker',
  components: {
    Btn,
  },
  data() {
    return {
      tours,
      moment,
    }
  },
  methods: {
    getDate(date) {
      return moment(date).format('D M')
    },
    getYear(date) {
      return moment(date).format('Y')
    },
  },
}
</script>

<style lang="scss">
.tours-wrap {
  display: block;
  position: relative;
  z-index: 1;
  .tours-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    &:not(:last-child) {
      border-bottom: 1px solid $color-gray;
    }
    &:last-child {
      padding-bottom: none;
    }
    @media (max-width: $lx) {
      padding: 15px 0;
    }
    @media (max-width: $md) {
      align-items: flex-start;
    }
  }
  .tour-date {
    flex: 0 0 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-right: 50px;
    @media (max-width: $lx) {
      flex: 0 0 120px;
    }
    @media (max-width: $md) {
      flex: 0 0 100px;
    }
    @media (max-width: $sm) {
      flex: 0 0 85px;
      margin-right: 25px;
    }

    span{
      flex: 0 0 50px;
      text-align: left;
      @include font(32px, 38px, 800);
      @include font-secondary;
      text-transform: uppercase;
      @media (max-width: $lx) {
        @include font(24px, 25px);
        flex: 0 0 40px;
      }
      @media (max-width: $sm) {
        @include font(18px, 22px);
        flex: 0 0 30px;
      }
      &.tour-date-year {
        font: inherit;
        font-size: 24px;
         @media (max-width: $lx) {
          font-size: 16px;
        }
         @media (max-width: $sm) {
          font-size: 14px;
        }
      }
    }
  }
  .tour-text {
    flex: 1 1 600px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    @media (max-width: $md) {
        display: block;
    }
    .tour-name {
      flex: 1 0 auto;
      @include font(24px, 30px);
      color: $color-gray;
      text-align: left;
      @media (max-width: $lx) {
        @include font(18px, 20px);
      }
      @media (max-width: $md) {
        margin-bottom: 15px;
      }
      @media (max-width: $sm) {
        @include font(14px, 17px);
      }
    }
    .tour-arrows {
      flex: 0 1 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      overflow: hidden;
      white-space: nowrap;
      margin-left: 40px;
      @include font(40px, 30px, none, 0.02em);
      color: $color-white;
      opacity: 0.2;
      text-align: right;
      @media (max-width: $md) {
        display: none;
      }
    }
    .tour-action {
      flex: 0 0 270px;
      text-align: right;
      @media (max-width: $md) {
        text-align: initial;
      }
      .button {
        width: 220px;
        @media (max-width: $lx) {
          width: 200px;
        }
        @media (max-width: $sm) {
          padding: 13px 22px;
          @include font(12px, 14px);
          width: auto;
        }
      }
    }
  }
}
</style>
